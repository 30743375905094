import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useMemo } from "react";

import BaseCardAction from "./CardActions/BaseCardActionAsync";
import BookmarkAction from "./CardActions/BookmarkAction";
import EllipsisAction from "./CardActions/EllipsisAction";
import ExportAction from "./CardActions/ExportActionAsync";
import FollowAction from "./CardActions/FollowAction";
import HeartAction from "./CardActions/HeartAction";
import LikeAction from "./CardActions/LikeAction";
import ListAction from "./CardActions/ListActionAsync";
import ListenAction from "./CardActions/ListenAction";
import PlayAction from "./CardActions/PlayAction";
import RateAction from "./CardActions/RateAction";
import ReplyAction from "./CardActions/ReplyActionAsync";
import ShareAction from "./CardActions/ShareAction";
import VoteAction from "./CardActions/VoteAction";

import useActivityContext from "hooks/useActivityContext";
import useLoggedInUser from "hooks/useLoggedInUser";
import useReviewReply from "hooks/useReviewReply";

const CardActions = (props) => {
  const { actions, entity, entity_type } = props;

  const activityContext = useActivityContext();
  const feedActivityContext = useMemo(
    () => ({
      ...(activityContext || {}),
      componentContext: "cardAction",
    }),
    [activityContext]
  );

  const loggedInUser = useLoggedInUser();
  const { canReply } = useReviewReply(entity_type === "review" ? entity : null);

  return actions.map((action, index) => {
    if (!action) {
      return null;
    }
    const actionProps = {
      ...props,
      ...action, // spread AND pass action, as some cards expect the action itself
      action,
      index,
      feedActivityContext,
    };

    if (action.type === "custom") {
      if (action.render) {
        return action.render({ index });
      }
      const key = `custom-${index}`;

      return <BaseCardAction {...actionProps} key={key} id={key} />;
    }
    if (action.type === "play") {
      return <PlayAction {...actionProps} key="play" id="play" />;
    }
    if (action.type === "listen") {
      return <ListenAction {...actionProps} key="listen" id="listen" />;
    }
    if (action.type === "bookmark") {
      return <BookmarkAction {...actionProps} key="bookmark" id="bookmark" />;
    }
    if (action.type === "rate") {
      return <RateAction {...actionProps} key="rate" id="rate" />;
    }
    if (action.type === "list") {
      return (
        <ListAction {...actionProps} key="addToUserList" id="addToUserList" />
      );
    }
    if (action.type === "share") {
      return <ShareAction {...actionProps} key="share" id="share" />;
    }
    if (action.type === "reply" && canReply) {
      return <ReplyAction {...actionProps} key="reply" id="reply" />;
    }
    if (action.type === "ellipsis") {
      return <EllipsisAction {...actionProps} key="ellipsis" id="ellipsis" />;
    }
    if (action.type === "export") {
      return <ExportAction {...actionProps} key="export" id="export" />;
    }
    if (action.type === "like") {
      return <LikeAction {...actionProps} key="like" id="like" />;
    }
    if (action.type === "heart") {
      return <HeartAction {...actionProps} key="heart" id="heart" />;
    }
    if (
      action.type === "follow" &&
      (!loggedInUser ||
        entity_type !== "user" ||
        entity.get("id") !== loggedInUser.get("id"))
    ) {
      return <FollowAction {...actionProps} key="follow" id="follow" />;
    }

    if (action.type === "vote") {
      return <VoteAction {...actionProps} key="vote" id="vote" />;
    }

    return null;
  });
};

CardActions.propTypes = {
  actions: PropTypes.array.isRequired,
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  entity_id: PropTypes.string,
  podcast: PropTypes.instanceOf(Map),
  brand: PropTypes.instanceOf(Map),
  mobile: PropTypes.bool,
  buttonProps: PropTypes.object,
  showShare: PropTypes.bool,
  regular: PropTypes.bool,
  customButtonStyles: PropTypes.object,
  customNoLabelButtonStyles: PropTypes.object,
  basicTooltipProps: PropTypes.object,
};

CardActions.defaultProps = {
  entity: null,
  entity_type: null,
  entity_id: null,
  podcast: null,
  mobile: false,
  regular: false,
  buttonProps: {},
  showShare: false,
  customButtonStyles: {},
  customNoLabelButtonStyles: {},
  basicTooltipProps: {},
};
export default memo(CardActions);
