import { faStar as faStarOpen } from "@fortawesome/pro-regular-svg-icons/faStar";
import { faStar } from "@fortawesome/pro-solid-svg-icons/faStar";
import { faStarHalfAlt } from "@fortawesome/pro-solid-svg-icons/faStarHalfAlt";

import faStarPart2 from "../styles/icons/faStarPart2";
import faStarPart4 from "../styles/icons/faStarPart4";

import colours from "styles/colours";

export const pastLabels = {
  1: "Hated",
  2: "Disliked",
  3: "Neutral",
  4: "Liked",
  5: "Loved",
};

export const presentVerbLabels = {
  1: "Hate",
  2: "Dislike",
  3: "Rate",
  4: "Like",
  5: "Love",
};
export const pastVerbLabels = {
  1: "Hated",
  2: "Disliked",
  3: "Rated",
  4: "Liked",
  5: "Loved",
};

export const podcastVerbLabels = {
  ...presentVerbLabels,
  3: pastVerbLabels[3],
};

export const episodeVerbLabels = {
  ...pastVerbLabels,
};

export const getRatingIcon = (userRating) =>
  userRating &&
  [faStarOpen, faStarPart2, faStarHalfAlt, faStarPart4, faStar][userRating - 1];

export const getRatingVariationStyles = (userRating) => {
  const customButtonBackgroundColor = getRatingVariationColor(userRating);

  return {
    color: customButtonBackgroundColor,
    backgroundColor: "white",
    borderColor: customButtonBackgroundColor,
    boxShadow: "none",
  };
};

export const getRatingVariationColor = (userRating) =>
  userRating && colours.stars[userRating] ? colours.stars[userRating] : null;

export const getRatingButtonStyles = (userRating) => {
  const customButtonBackgroundColor = getRatingVariationColor(userRating);
  const customButtonHoverBackgroundColor =
    userRating && colours.stars.lighter[userRating]
      ? colours.stars.lighter[userRating]
      : null;
  const styles = getRatingVariationStyles(userRating);

  return {
    button: {
      ...(customButtonBackgroundColor && {
        ...styles,
      }),
    },
    hover: {
      ...(customButtonHoverBackgroundColor && {
        ...styles,
        backgroundColor: customButtonHoverBackgroundColor,
      }),
    },
  };
};
