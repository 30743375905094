import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo } from "react";

import EntityLikes from "components/Entities/EntityLikes";

const likesWithMarginStyles = {
  likesContainer: {
    marginRight: ".75rem",
  },
};

const mobileLikesWithMarginStyles = {
  likesContainer: {
    marginRight: "1rem",
  },
};

const HeartAction = (props) => {
  const { actions, mobile, entity, entity_type, index } = props;

  const likeStyles = mobile
    ? mobileLikesWithMarginStyles
    : likesWithMarginStyles;

  return (
    <EntityLikes
      {...props}
      styles={index === actions.length - 1 ? null : likeStyles}
      entity_type={entity_type}
      entity={entity}
      showRatingsCount
      horizontal
      grey
    />
  );
};

HeartAction.propTypes = {
  actions: PropTypes.array.isRequired,
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  index: PropTypes.number.isRequired,
  mobile: PropTypes.bool,
};

HeartAction.defaultProps = {
  entity: null,
  entity_type: null,
  mobile: false,
};

export default memo(HeartAction);
