import Loadable from "@loadable/component";

import LoadingOverlay from "components/Common/LoadingOverlay";

const ReplyAction = Loadable(() =>
  import(/* webpackChunkName: "ReplyAction" */ "./ReplyAction")
);

function ReplyActionLoading(props) {
  return <LoadingOverlay {...props} span />;
}

const ReplyActionContainer = (props) => {
  return <ReplyAction {...props} fallback={<ReplyActionLoading />} />;
};

export default ReplyActionContainer;
