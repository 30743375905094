import { faThumbsUp } from "@fortawesome/pro-solid-svg-icons/faThumbsUp";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import {
  deleteVoteForIntegrationPromise,
  voteForIntegrationPromise,
} from "routines/integration";

import BaseCardAction from "./BaseCardActionAsync";

import useRoutinePromises from "hooks/useRoutinePromises";

const VoteAction = (props) => {
  const { entity, action, feedActivityContext, index } = props;

  const { voteForIntegration, deleteVoteForIntegration } = useRoutinePromises({
    voteForIntegration: voteForIntegrationPromise,
    deleteVoteForIntegration: deleteVoteForIntegrationPromise,
  });

  const handleVoteClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (entity.getIn(["user_data", "voted"])) {
        deleteVoteForIntegration({
          integration_id: entity.get("id"),
          analyticsVariables: feedActivityContext,
        });
      } else {
        voteForIntegration({
          integration_id: entity.get("id"),
          analyticsVariables: feedActivityContext,
        });
      }
    },
    [deleteVoteForIntegration, entity, feedActivityContext, voteForIntegration]
  );

  const voted = entity && entity.getIn(["user_data", "voted"]);

  return (
    <BaseCardAction
      {...props}
      index={index}
      {...action}
      icon={faThumbsUp}
      iconSpin={false}
      tooltip={voted ? "Voted" : "Vote"}
      actioned={voted}
      onClick={handleVoteClick}
    />
  );
};

VoteAction.propTypes = {
  entity: PropTypes.instanceOf(Map),
  action: PropTypes.object.isRequired,
  feedActivityContext: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

VoteAction.defaultProps = {
  entity: null,
};

export default memo(VoteAction);
