/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-star-part-4",
  icon: [
    513,
    490,
    [],
    null,
    "M486.277017,175.127467 L346.458467,154.743375 L283.966007,28.0350926 C272.768822,5.45006881 240.325865,5.16315321 229.033353,28.0350926 L166.540893,154.743375 L26.7223428,175.127467 C1.64853644,178.763912 -8.40005151,209.675095 9.78310763,227.37975 L110.93908,325.951613 L87.0138705,465.196332 C82.7073328,490.3664 109.215717,509.218717 131.418498,497.447701 L256.49968,431.701039 L381.580862,497.447701 C403.783643,509.12339 430.292027,490.3664 425.98549,465.196332 L402.06028,325.951613 L503.216252,227.37975 C521.399412,209.675095 511.350824,178.763912 486.277017,175.127467 Z M352.774349,309.874059 L375.455634,442.324392 L336.259627,421.733116 L336.124083,199.67627 L449.049952,216.087238 L352.774349,309.874059 Z",
  ],
};
