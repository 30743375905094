import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { followEntityPromise, unfollowEntityPromise } from "routines/follow";

import BaseCardAction from "./BaseCardActionAsync";

import { entityTypeNames } from "utils/entity";
import getUserDisplayName from "utils/entity/getUserDisplayName";
import { getUserListEditUrl } from "utils/entity/userlist";

import useLoggedInUser from "hooks/useLoggedInUser";
import useRoutinePromises from "hooks/useRoutinePromises";

const FollowAction = (props) => {
  const { entity, entity_type, action, feedActivityContext, index } = props;

  const { followEntity, unfollowEntity } = useRoutinePromises({
    followEntity: followEntityPromise,
    unfollowEntity: unfollowEntityPromise,
  });

  const loggedInUser = useLoggedInUser();

  const isUserOwnedList =
    entity_type === "userlist" &&
    entity &&
    loggedInUser &&
    entity.getIn(["owners", 0]) === loggedInUser.get("id");
  const following = entity && entity.getIn(["user_data", "follows"]);
  const submitting = entity && entity.getIn(["user_data", "follow_saving"]);
  // const likedIcon = liked ? faHeartActioned : faHeartOutline;

  const getTo = useCallback(() => getUserListEditUrl(entity), [entity]);

  const handleFollowClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const fn =
        entity && entity.getIn(["user_data", "follows"])
          ? unfollowEntity
          : followEntity;

      fn({
        entity_type,
        entity_id: entity.get("id"),
        entity,
        analyticsVariables: feedActivityContext,
      });
    },
    [entity, unfollowEntity, followEntity, entity_type, feedActivityContext]
  );

  if (isUserOwnedList) {
    return (
      <BaseCardAction
        {...props}
        index={index}
        {...action}
        icon={faPencilAlt}
        tooltip="Edit List"
        getTo={getTo}
      />
    );
  }

  const entityTypeText =
    entityTypeNames[entity_type] && entityTypeNames[entity_type].singular;

  let icon;

  if (submitting) {
    icon = faCircleNotch;
  } else if (following) {
    icon = faCheck;
  } else {
    icon = faPlus;
  }

  return (
    <BaseCardAction
      {...props}
      index={index}
      {...action}
      icon={icon}
      iconSpin={submitting}
      label={following ? "Following" : "Follow"}
      tooltip={
        following
          ? `Following ${
              entity_type === "user"
                ? getUserDisplayName(entity, true)
                : entityTypeText
            }`
          : `Follow ${
              entity_type === "user"
                ? getUserDisplayName(entity, true)
                : entityTypeText
            }`
      }
      variation={following && "actioned"}
      onClick={handleFollowClick}
    />
  );
};

FollowAction.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  action: PropTypes.object.isRequired,
  feedActivityContext: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

FollowAction.defaultProps = {
  entity: null,
  entity_type: null,
};

export default memo(FollowAction);
