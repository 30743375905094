import Loadable from "@loadable/component";
const ListAction = Loadable(() =>
  import(/* webpackChunkName: "ListAction" */ "./ListAction")
);

function ListActionLoading(props) {
  return null;
}

const ListActionContainer = (props) => {
  return <ListAction {...props} fallback={<ListActionLoading />} />;
};

export default ListActionContainer;
