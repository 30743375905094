import Loadable from "@loadable/component";
const ExportAction = Loadable(() =>
  import(/* webpackChunkName: "ExportAction" */ "./ExportAction")
);

function ExportActionLoading(props) {
  return null;
}

const ExportActionContainer = (props) => {
  return <ExportAction {...props} fallback={<ExportActionLoading />} />;
};

export default ExportActionContainer;
