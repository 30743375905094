import Loadable from "@loadable/component";
const PlayButton = Loadable(() => import("./PlayButton"));

function ButtonAsyncLoading() {
  return null;
}

const PlayButtonContainer = (props) => {
  return <PlayButton {...props} fallback={<ButtonAsyncLoading />} />;
};

export default PlayButtonContainer;
