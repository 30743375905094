import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const voteForIntegration = createRoutine("INTEGRATION_VOTE");
export const voteForIntegrationPromise = promisifyRoutine(voteForIntegration);

export const deleteVoteForIntegration = createRoutine(
  "DELETE_INTEGRATION_VOTE"
);
export const deleteVoteForIntegrationPromise = promisifyRoutine(
  deleteVoteForIntegration
);
