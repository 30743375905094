import getCreatorDisplayName from "utils/entity/getCreatorDisplayName";
import getUserDisplayName from "utils/entity/getUserDisplayName";

export const getEntityDisplayName = (entity_type, entity, informal = false) => {
  switch (entity_type) {
    default:
    case "podcast":
    case "episode":
    case "userlist":
      return entity.get("title");
    case "user":
      return getUserDisplayName(entity, informal);
    case "creator":
      return getCreatorDisplayName(entity, informal);
  }
};

export const entityTypeNames = {
  user: {
    singular: "User",
    plural: "Users",
  },
  creator: { singular: "Creator", plural: "Creators" },
  podcast: { singular: "Podcast", plural: "Podcasts" },
  userlist: { singular: "List", plural: "Lists" },
  episode: { singular: "Episode", plural: "Episodes" },
  category: { singular: "Category", plural: "Categories" },
};
