import { faShareSquare } from "@fortawesome/free-solid-svg-icons/faShareSquare";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import ShareButton from "components/Buttons/Items/ShareButton";

import BaseCardAction from "./BaseCardActionAsync";

const ShareAction = (props) => {
  const { entity, entity_type, podcast, index, popoutProps } = props;

  const handlePreventDefault = useCallback((e) => {
    e.stopPropagation();
    e.preventDefault();
  }, []);

  const handleToggle = useCallback(
    (toggleMenu) => (e) => {
      e.preventDefault();
      e.stopPropagation();
      toggleMenu();
    },
    []
  );

  const renderShareButton = (index) => (shareProps) =>
    (
      <div {...shareProps} key={index}>
        <BaseCardAction
          {...props}
          index={index}
          onClick={handleToggle(shareProps.toggleMenu)}
          icon={faShareSquare}
          tooltip="Share"
        />
      </div>
    );

  return (
    <div
      onMouseDown={handlePreventDefault}
      onMouseUp={handlePreventDefault}
      onClick={handlePreventDefault}
    >
      <ShareButton
        renderButton={renderShareButton(index)}
        entity_type={entity_type}
        entity={entity}
        podcast={podcast}
        popoutProps={popoutProps}
      />
    </div>
  );
};

ShareAction.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  podcast: PropTypes.instanceOf(Map),
  action: PropTypes.object.isRequired,
  feedActivityContext: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  popoutProps: PropTypes.object,
};

ShareAction.defaultProps = {
  entity: null,
  entity_type: null,
  podcast: null,
  popoutProps: undefined,
};

export default memo(ShareAction);
