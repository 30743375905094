import { faEar } from "@fortawesome/pro-regular-svg-icons/faEar";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import {
  setAllListenedForPodcastPromise,
  setListenedPromise,
} from "routines/listens";

import BaseCardAction from "./BaseCardActionAsync";

import useRoutinePromises from "hooks/useRoutinePromises";

const ListenAction = (props) => {
  const { entity, entity_type, action, feedActivityContext } = props;
  const listened = entity && entity.getIn(["user_data", "listened"]);

  const { setListened, setAllListenedForPodcast } = useRoutinePromises({
    setListened: setListenedPromise,
    setAllListenedForPodcast: setAllListenedForPodcastPromise,
  });

  const handleListenClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (entity_type === "podcast") {
        return setAllListenedForPodcast({
          entity,
          entity_type,
          listened: !entity.getIn(["user_data", "listened"], false),
          podcast_id: entity.get("id"),
          analyticsVariables: feedActivityContext,
        });
      }

      return setListened({
        entity,
        entity_type,
        listened: !entity.getIn(["user_data", "listened"], false),
        episode_id: entity.get("id"),
        analyticsVariables: feedActivityContext,
      });
    },
    [
      entity_type,
      setListened,
      entity,
      feedActivityContext,
      setAllListenedForPodcast,
    ]
  );

  return (
    <BaseCardAction
      {...props}
      {...action}
      icon={faEar}
      tooltip={listened ? "Mark as Not Listened" : "Mark as Listened"}
      actioned={listened}
      onClick={handleListenClick}
    />
  );
};

ListenAction.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  action: PropTypes.object.isRequired,
  feedActivityContext: PropTypes.object.isRequired,
};

ListenAction.defaultProps = {
  entity: null,
  entity_type: null,
};

export default memo(ListenAction);
