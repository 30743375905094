import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";

import PlayButton from "components/Buttons/PlayButtonAsync";

import BaseCardAction from "./BaseCardActionAsync";

const PlayAction = (props) => {
  const { entity, entity_id, entity_type, regular, action, index } = props;

  const renderButton = useCallback(
    (buttonProps) => {
      const { icon, iconSpin, label, disabled, isPlaying, isPlayed, onClick } =
        buttonProps;

      return (
        <BaseCardAction
          {...props}
          index={index}
          {...action}
          label={label}
          icon={icon}
          iconSpin={iconSpin}
          disabled={disabled}
          actioned={isPlaying || isPlayed}
          onClick={onClick}
          styles={{
            buttonIcon: {
              paddingLeft: 2,
            },
          }}
        />
      );
    },
    [action, index, props]
  );

  return (
    <PlayButton
      {...props}
      key="play"
      entity_type={entity_type}
      entity_id={entity ? entity.get("id") : entity_id}
      iconSize=".9rem"
      hideLabel={action.hideLabel || false}
      filled={!regular}
      render={renderButton}
    />
  );
};

PlayAction.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  entity_id: PropTypes.string,
  regular: PropTypes.bool,
  action: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

PlayAction.defaultProps = {
  entity: null,
  entity_type: null,
  entity_id: null,
  regular: false,
};

export default memo(PlayAction);
