import { faCircleNotch } from "@fortawesome/free-solid-svg-icons/faCircleNotch";
import { faHeart as faHeartActioned } from "@fortawesome/free-solid-svg-icons/faHeart";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { setLikedPromise } from "routines/likes";

import BaseCardAction from "./BaseCardActionAsync";

import { entityTypeNames } from "utils/entity";

import useRoutinePromises from "hooks/useRoutinePromises";

const LikeAction = (props) => {
  const { entity, entity_type, action, feedActivityContext, index } = props;

  const { setLiked } = useRoutinePromises({ setLiked: setLikedPromise });

  const liked = entity && entity.getIn(["user_data", "is_liked"]);
  const submitting = entity && entity.getIn(["user_data", "like_saving"]);
  // const likedIcon = liked ? faHeartActioned : faHeartOutline;
  const likedIcon = faHeartActioned;
  const entityTypeText =
    entityTypeNames[entity_type] && entityTypeNames[entity_type].singular;

  const handleLikeClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setLiked({
        entity,
        entity_id: entity.get("id"),
        entity_type,
        liked: !entity.getIn(["user_data", "is_liked"]),
        analyticsVariables: feedActivityContext,
      });
    },
    [entity, entity_type, feedActivityContext, setLiked]
  );

  return (
    <BaseCardAction
      {...props}
      index={index}
      {...action}
      icon={submitting ? faCircleNotch : likedIcon}
      iconSpin={submitting}
      label={liked ? "Unlike" : "Like"}
      tooltip={liked ? `Unlike ${entityTypeText}` : `Like ${entityTypeText}`}
      actioned={liked}
      onClick={handleLikeClick}
    />
  );
};

LikeAction.propTypes = {
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  action: PropTypes.object.isRequired,
  feedActivityContext: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

LikeAction.defaultProps = {
  entity: null,
  entity_type: null,
};

export default memo(LikeAction);
