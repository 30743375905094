import { faStar } from "@fortawesome/free-solid-svg-icons/faStar";
import { faStar as faStarRegular } from "@fortawesome/pro-regular-svg-icons/faStar";
import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, useCallback, useMemo } from "react";

import RatingWithHover from "components/Rating/RatingWithHoverAsync";

import BaseCardAction from "./BaseCardActionAsync";

import ratingActionCreators from "actions/rating";
import { getRatingIcon, getRatingVariationColor } from "utils/rating";

import useActionCreators from "hooks/useActionCreators";
import useLoggedInUser from "hooks/useLoggedInUser";

import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";

const RateAction = (props) => {
  const {
    actions,
    entity,
    entity_id,
    entity_type,
    regular,
    action,
    feedActivityContext,
    index,
    customButtonStyles: passedCustomButtonStyles,
    customNoLabelButtonStyles: passedCustomNoLabelButtonStyles,
  } = props;

  const loggedInUser = useLoggedInUser();

  const { openEpisodeRatingModal, openPodcastRatingModal } =
    useActionCreators(ratingActionCreators);

  const hasNoLabelButton = useMemo(
    () =>
      actions &&
      actions.some((action) => action && action.asButton && action.noLabel),
    [actions]
  );

  const rating = entity && entity.getIn(["user_data", "rating", "rating"]);

  const buttonActionStyles = useMemo(
    () => ({
      ...passedCustomButtonStyles,
      button: {
        ...(passedCustomButtonStyles && passedCustomButtonStyles.button),
      },
    }),
    [passedCustomButtonStyles]
  );

  const noLabelButtonActionStyles = useMemo(
    () => ({
      ...passedCustomButtonStyles,
      ...passedCustomNoLabelButtonStyles,
      button: {
        ...(passedCustomButtonStyles && passedCustomButtonStyles.button),
        ...(passedCustomNoLabelButtonStyles &&
          passedCustomNoLabelButtonStyles.button),
        width: 30,
        height: 26,
      },
    }),
    [passedCustomButtonStyles, passedCustomNoLabelButtonStyles]
  );

  const ratingStyles = useMemo(() => {
    if (rating) {
      return {
        actioned: {
          color: getRatingVariationColor(rating),

          ":focus-visible": {
            color: getRatingVariationColor(rating),
          },
          ...getHoverQuery({
            color: getRatingVariationColor(rating),
          }),
        },
        hovered: {
          ...gStyles.avalonBold,
        },
      };
    }

    return null;
  }, [rating]);

  // Note: Changes were made here to ensure styles are passed properly in BaseCardAction. May have side effects.
  const styles = useMemo(
    () => ({
      ...ratingStyles,
      ...action.styles,
      ...(hasNoLabelButton ? noLabelButtonActionStyles : buttonActionStyles),
    }),
    [
      action.styles,
      buttonActionStyles,
      hasNoLabelButton,
      noLabelButtonActionStyles,
      ratingStyles,
    ]
  );

  const handleRateButtonClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();

      if (entity_type === "episode") {
        openEpisodeRatingModal(entity ? entity.get("id") : entity_id, {
          entity,
          analyticsVariables: feedActivityContext,
        });
      } else {
        openPodcastRatingModal(entity ? entity.get("id") : entity_id, {
          entity,
          analyticsVariables: feedActivityContext,
        });
      }
    },
    [
      entity_type,
      openEpisodeRatingModal,
      entity,
      entity_id,
      feedActivityContext,
      openPodcastRatingModal,
    ]
  );

  const rateIcon = useMemo(() => {
    if (rating) {
      return getRatingIcon(rating);
    }

    return regular ? faStarRegular : faStar;
  }, [rating, regular]);

  const tooltip = useMemo(() => {
    if (rating) {
      return (
        <div className={css(styles.ratingContainer)}>
          <div>You rated this</div>
          <div className={css(styles.ratingIconsContainer)}>
            <RatingWithHover
              id={entity ? entity.get("id") : null}
              rating={rating}
              fontSize={14}
              hideMeta
              isAggregate={false}
              author={loggedInUser}
              ariaLabelFunc={({ rating }) =>
                `${Math.round(rating * 100) / 100} out of 5 stars`
              }
            />
          </div>
          <div>Click to change your rating</div>
        </div>
      );
    }

    return "Leave a Rating";
  }, [
    entity,
    loggedInUser,
    rating,
    styles.ratingContainer,
    styles.ratingIconsContainer,
  ]);

  return (
    <BaseCardAction
      {...props}
      index={index}
      {...action}
      icon={rateIcon}
      actioned={!!rating}
      tooltip={tooltip}
      hoverLabel={rating}
      styles={styles}
      onClick={handleRateButtonClick}
    />
  );
};

RateAction.propTypes = {
  actions: PropTypes.array.isRequired,
  entity: PropTypes.instanceOf(Map),
  entity_type: PropTypes.string,
  entity_id: PropTypes.string,
  regular: PropTypes.bool,
  action: PropTypes.object.isRequired,
  feedActivityContext: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  customButtonStyles: PropTypes.object,
  customNoLabelButtonStyles: PropTypes.object,
};

RateAction.defaultProps = {
  entity: null,
  entity_type: null,
  entity_id: null,
  regular: false,
  customButtonStyles: {},
  customNoLabelButtonStyles: {},
};

export default memo(RateAction);
