/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-star-part-2",
  icon: [
    513,
    490,
    [],
    null,
    "M485.777337,175.138905 L345.958787,154.754814 L283.466327,28.046531 C272.269142,5.46150716 239.826185,5.17459157 228.533673,28.046531 L166.041213,154.754814 L26.2226628,175.138905 C1.14885644,178.77535 -8.89973151,209.686534 9.28342763,227.391189 L110.4394,325.963051 L86.5141905,465.20777 C82.2076528,490.377838 108.716037,509.230155 130.918818,497.459139 L256,431.712477 L381.081182,497.459139 C403.283963,509.134828 429.792347,490.377838 425.48581,465.20777 L401.5606,325.963051 L502.716572,227.391189 C520.899732,209.686534 510.851144,178.77535 485.777337,175.138905 Z M352.274669,309.885498 L374.955954,442.335831 L256,379.843371 L178.468048,420.574097 L178.704044,199.349327 L196.473892,196.766921 L256,76.1838654 L315.526108,196.766921 L448.550272,216.098677 L352.274669,309.885498 Z",
  ],
};
